
import React from "react"
export const columns =  [
     
      {
        accessorKey: 'link',
        header: 'Link'
        
      },
      {
        accessorKey: 'title',
        header: 'Title',
      },



  ]

 