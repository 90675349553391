export const columns =  [
     
    {
      accessorKey: 'percentage',
      header: 'Gratuity percentage'
      
    },
    


]