import { CImage } from "@coreui/react"
import React from "react"
 
export const columns =  [
    // {
    //     accessorKey: 'additionalStopId',
    //     header: 'ID',

    //   },    
      // {
      //   accessorKey: 'stopType',
      //   header: 'Stop-type'
      // },
      {
        accessorKey: 'additionalStopPrice',
        header: 'Additional-stop-Price',
      },

      // {
      //   accessorKey: 'currency',
      //   header: 'Currency',
      // },    
    
   
   
  ]

 