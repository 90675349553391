
import React from "react"
export const columns =  [
    // {
    //     accessorKey: 'userId',
    //     header: 'ID',
    //   },    
      {
        accessorKey: 'fullName',
        header: 'Full Name'
        
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },

      {
        accessorKey: 'phoneNumber',
        header: 'Phone Number',
      },    
      // {
      //   accessorKey: 'role',
      //   header: 'Role',
      // },

      {
        accessorKey: 'password',
        header: 'Password',
      }

  ]

 