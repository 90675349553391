
import React from "react"
export const columns =  [
      
      {
        accessorKey: 'preferenceName',
        header: 'Preference Name'
        
      },
      {
        accessorKey: 'preferencePrice',
        header: 'Preference-Price',
      },

      // {
      //   accessorKey: 'currency',
      //   header: 'Currency',
      // },    
      // {
      //   accessorKey: 'status',
      //   header: 'Status',
      // }
  ]

 